import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import IconCube from '../Icons/IconCube';
import IconDoor from '../Icons/IconDoor';
import IconWindow from '../Icons/IconWindow';

const getIcon = (type) => {
  switch (type) {
    case 'cube':
      return <IconCube size="50px" color="black" />;
    case 'door':
      return <IconDoor size="40px" color="black" />;
    case 'window':
      return <IconWindow size="40px" color="black" />;
    default:
      return <IconCube size="40px" color="black" />;
  }
};

const CalcField = ({ icon, children, ...rest }) => (
  <Box
    className={`cfield`}
    {...rest}
    direction="row"
    align="center"
    gap="small"
  >
    {icon && (
      <Box width="80px" className="cfield__icon">
        {getIcon(icon)}
      </Box>
    )}
    {children}
  </Box>
);

CalcField.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
};

export default CalcField;
