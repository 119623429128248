import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Text, Button } from 'grommet';
import { Field } from 'formik';
import { map } from '../../lib/nodash';
import { FormNextLink } from 'grommet-icons';

import useHover from '../useHover';
import ImgixImage from '../ImgixImage';
import ProductCardInfo from '../Product/ProductCardInfo';
import ProductBadges from '../Product/ProductBadges';
import SmartLink from '../SmartLink';
import InputSelectWithDescription from '../Inputs/InputSelectWithDescription';
import TextUnderlined from '../TextUnderlined';
import formatCurrency from '../../lib/formatCurrency';
import useAdjustedHeadingSize from '../useAdjustedHeadingSize';

const ProductCard = ({
  imageUrl,
  collectionImageUrl,
  productField,
  productType,
  headingLevel = 5,
  title,
  cta,
  overlay,
  shopifyProducts,
  variants,
  description,
  showDescription,
  showProductType = true,
  showTitle = true,
  showPrice,
  sampleVariant,
  backgroundColor,
  isDark,
  detailsPath,
  tags,
  onAddToCart,
  price,
  pad,
  imageFit = 'contain',
  imageFill = false,
  imageSrcSetOptions = {},
  sizes,
  ...rest
}) => {
  const [hoverRef, isHovered] = useHover();
  const productOptions = useMemo(
    () =>
      map(
        (x) => ({ label: x.productType, value: x.shopifyId }),
        shopifyProducts
      ),
    [shopifyProducts]
  );

  const titleStyle = {
    ...useAdjustedHeadingSize(headingLevel),
    letterSpacing: '1px',
  };

  return (
    <Box {...rest} ref={hoverRef} fill>
      <Stack fill anchor="top-right">
        <Stack fill>
          <Box flex={true} align="center" justify="center" fill>
            <SmartLink
              to={detailsPath}
              plain
              title={title}
              fill
              a11yTitle={title}
            >
              <Box
                flex={true}
                gap="small"
                pad={
                  pad
                    ? pad
                    : {
                        horizontal: 'large',
                        vertical: 'small',
                        bottom: 'medium',
                      }
                }
                fill
                justify="center"
                align="center"
              >
                <ImgixImage
                  alignSelf="center"
                  src={collectionImageUrl}
                  srcSetOptions={{
                    q: 80,
                    auto: ['format', 'compress'],
                    w: sizes ? undefined : 200,
                    ...imageSrcSetOptions,
                  }}
                  alt={title}
                  fit={imageFit}
                  fill={imageFill}
                  width="100%"
                  sizes={sizes}
                  lazy={true}
                />
                {productField ? (
                  <Box width="auto" alignSelf="center">
                    <Field
                      component={InputSelectWithDescription}
                      name={productField}
                      size="small"
                      options={productOptions}
                      alignSelf="center"
                      plain
                    />
                  </Box>
                ) : (
                  <>
                    {showProductType && productType && (
                      <Box margin={{ top: 'small' }}>
                        <Box margin={{ vertical: 'small' }}>
                          <Text align="center" textAlign="center">
                            {productType}
                          </Text>
                        </Box>
                      </Box>
                    )}
                  </>
                )}
                {(showTitle || showDescription) && (
                  <Box>
                    {showTitle && (
                      <Text
                        textAlign="center"
                        weight={500}
                        margin="none"
                        style={titleStyle}
                      >
                        {title}
                      </Text>
                    )}
                    {showDescription && (
                      <Box>
                        <Text textAlign="center">{description}</Text>
                      </Box>
                    )}
                  </Box>
                )}
                {showPrice && (
                  <Box align="center">
                    <TextUnderlined
                      size="small"
                      weight={500}
                      textAlign="center"
                    >
                      {price && formatCurrency(price)}
                    </TextUnderlined>
                  </Box>
                )}
              </Box>
            </SmartLink>
            {cta && (
              <Button
                size="xsmall"
                fill="horizontal"
                secondary
                icon={<FormNextLink size="18px" />}
                reverse
                label={cta}
                style={{
                  borderBottom: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                }}
              />
            )}
          </Box>
          {overlay && isHovered && (
            <Box flex={true} fill background={{ color: backgroundColor }}>
              <ProductCardInfo
                fill
                title={title}
                imageUrl={imageUrl}
                sampleVariant={sampleVariant}
                backgroundColor={backgroundColor}
                isDark={isDark}
                variants={variants}
                description={description}
                detailsPath={detailsPath}
                onAddToCart={onAddToCart}
                showPrice={showPrice}
              />
            </Box>
          )}
        </Stack>
        {overlay && isHovered ? null : <ProductBadges tags={tags} />}
      </Stack>
    </Box>
  );
};

ProductCard.propTypes = {
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  collectionImageUrl: PropTypes.string.isRequired,
  productField: PropTypes.string,
  productOptions: PropTypes.array,
  shopifyProducts: PropTypes.array,
  variants: PropTypes.array,
  productType: PropTypes.string,
  headingLevel: PropTypes.number,
  description: PropTypes.string,
  showDescription: PropTypes.bool,
  showProductType: PropTypes.bool,
  showPrice: PropTypes.bool,
  showTitle: PropTypes.string,
  price: PropTypes.string,
  cta: PropTypes.string,
  overlay: PropTypes.bool,
  sampleVariant: PropTypes.object,
  backgroundColor: PropTypes.string,
  isDark: PropTypes.bool,
  detailsPath: PropTypes.string.isRequired,
  tags: PropTypes.array,
  onAddToCart: PropTypes.func,
  pad: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imageFit: PropTypes.oneOf(['fit', 'fill', '']),
  imageFill: PropTypes.bool,
  sizes: PropTypes.string,
};

export default memo(ProductCard);
