import { createSelector } from 'reselect';
import { find, includes, get, map, isEqual } from '../../lib/nodash';
import extractGid from '../../lib/extractGid';

const makeSelectPaintCollectionByProductId = () => {
  return createSelector(
    (state) => state.product.paintCollections,
    (_, shopifyId) => extractGid(shopifyId),
    (paintCollections, shopifyId) => {
      const isGeneric = isEqual('_generic', shopifyId);
      if (isGeneric) {
        return {
          title: 'Backdrop Paint',
          metafields: [
            {
              key: 'collection_image',
              namespace: 'images',
              value: [
                {
                  original_src:
                    'https://cdn.shopify.com/s/files/1/0051/6295/8946/t/2/assets/can-generic.png?15168478235785077730',
                },
              ],
            },
          ],
        };
      }
      return find((x) => {
        return includes(shopifyId, map('shopifyId', get('products', x)));
      }, paintCollections);
    }
  );
};

export default makeSelectPaintCollectionByProductId;
