import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { get, isEqual } from '../../lib/nodash';
import {
  Box,
  Heading,
  Button,
  TextInput,
  Text,
  ResponsiveContext,
  ThemeContext,
} from 'grommet';
import { Formik, Form, Field } from 'formik';
import { FormNextLink } from 'grommet-icons';
import * as Yup from 'yup';

import CalcField from './CalcField';
import PaintProductCard from '../Paint/PaintProductCard';
import Loading from '../Loading';
import InputWithHint from '../Inputs/InputWithHint';
import InputRadioButtonGroup from '../Inputs/InputRadioButtonGroup';
import IconMeasure from '../Icons/IconMeasure';
import { shopifyClient } from '../../gatsby-theme-apollo/client';
import {
  calculateGallons,
  calculateSquareFootage,
} from '../../lib/calculations/paintCalculations';
import defaultPaintProduct from '../../lib/defaultPaintProduct';
import extractGid from '../../lib/extractGid';
import encodeGid from '../../lib/encodeGid';
import { GET_FULL_PRODUCT } from '../../queries/productQueries';
import useAdjustedHeadingSize from '../useAdjustedHeadingSize';

const dimensionHintText = 'Walk it out or use the Measure tool on iPhone';

const validationSchema = Yup.object().shape({
  length: Yup.number().min(0).transform(getNumberValue),
  width: Yup.number().min(0).transform(getNumberValue),
  height: Yup.number().min(0).transform(getNumberValue),
});

const CalcForm = ({
  onCalculate,
  productId,
  onBrowse,
  subtitle,
  paintCollections = [],
}) => {
  const size = useContext(ResponsiveContext);
  const theme = useContext(ThemeContext);
  const isMobile = size === 'small' || size === 'medsmall';
  const { data, loading } = useQuery(GET_FULL_PRODUCT, {
    client: shopifyClient,
    variables: {
      productId: encodeGid(productId),
    },
    skip: !productId,
  });

  const product = get('node', data) || defaultPaintProduct;
  const paintCollection = paintCollections.find((x) =>
    product.id
      ? x.products.map((p) => p.shopifyId).indexOf(extractGid(product.id)) > -1
      : false
  );

  const labelProps = {
    size: isMobile ? 'medium' : 'xlarge',
    style: { lineHeight: '1em' },
  };
  const labelContainerProps = {
    pad: { bottom: '0.6em', right: 'xlarge' },
  };
  const inputContainerProps = {
    fill: 'horizontal',
    style: { top: '-1em', position: 'relative' },
  };

  const isGeneric = isEqual('_generic', get('id', product));
  const headingStyle = useAdjustedHeadingSize(3);

  return (
    <div className="calc calc--step1" id="calc--step1">
      <Box pad={{ bottom: 'medium' }}>
        <Box
          direction={isMobile ? 'column' : 'row'}
          align="center"
          gap="medium"
        >
          <Heading
            level={1}
            textAlign={isMobile ? 'center' : 'start'}
            margin="none"
            style={headingStyle}
          >
            Calculating Paint
          </Heading>
          {subtitle && (
            <Heading margin="none" level={4} size="small">
              {subtitle}
            </Heading>
          )}
        </Box>
      </Box>
      <Formik
        initialValues={{
          areaType: 'Room',
          doors: '',
          windows: '',
          length: '',
          width: '',
          height: '',
          productId: get('id', product),
        }}
        validationSchema={validationSchema}
        enableReinitialize={true} // after product ID is fetched
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const parsedValues = {
            ...values,
            length: getNumberValue(values.length),
            width: getNumberValue(values.width),
            height: getNumberValue(values.height),
            windows: getNumberValue(values.windows),
            doors: getNumberValue(values.doors),
          };

          const sqFt = calculateSquareFootage(parsedValues, values.areaType);
          await resetForm();
          setSubmitting(false);
          onCalculate(
            { sqFt, gallons: calculateGallons(sqFt) },
            parsedValues,
            product.id === '_generic' ? product.id : extractGid(product.id)
          );
        }}
      >
        {({ values, isValid, setFieldValue }) => {
          const setNumberValue = (e) => {
            const { name, value } = e.target;
            setFieldValue(name, value.replace(/[^0-9]/g, ''));
          };
          const isWall = values.areaType === 'Wall';

          return (
            <Box>
              <Form>
                <Box className="calc__inner" gap="medium">
                  <Box
                    direction={isMobile ? 'column' : 'row'}
                    gap="large"
                    align="stretch"
                    wrap={false}
                    className="calc__row calc__row--header"
                    id="calc__row"
                  >
                    {loading ? (
                      <Box
                        flex={true}
                        justify="center"
                        align="center"
                        pad="large"
                      >
                        <Loading size="large" />
                      </Box>
                    ) : (
                      <Box
                        width={{ min: '100px', max: 'medium' }}
                        flex={{ grow: 0, shrink: 1 }}
                        align="center"
                        gap="medium"
                      >
                        <Heading level={5} textAlign="center">
                          Calculate Quantity For
                        </Heading>
                        <PaintProductCard
                          {...product}
                          collection={paintCollection}
                          productType={get('productType', product)}
                          routeType="paint"
                          overlay={false}
                          headingLevel={4}
                          onClick={
                            isGeneric
                              ? (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onBrowse({});
                                }
                              : null
                          }
                          cta={isGeneric ? 'Select your color' : null}
                          border={
                            isGeneric
                              ? { side: 'all', size: 'small' }
                              : undefined
                          }
                          width={{ max: '330px' }}
                          link={false}
                        />
                      </Box>
                    )}
                    <Box
                      className="room-fields"
                      flex={true}
                      justify="between"
                      wrap={false}
                      pad={isMobile ? 'none' : 'large'}
                      gap="large"
                    >
                      <Box>
                        <CalcField icon="cube">
                          <Box>
                            <Heading level={5} size="small">
                              I’m painting a:
                            </Heading>
                            <Field
                              component={InputRadioButtonGroup}
                              name="areaType"
                              gap="large"
                              direction="row"
                              style={{ fontSize: theme.text.xlarge.size }}
                              options={['Room', 'Wall']}
                            />
                          </Box>
                        </CalcField>
                      </Box>

                      <Box>
                        <Box
                          direction={isMobile ? 'column' : 'row'}
                          gap="large"
                        >
                          <CalcField icon="door">
                            <Field
                              component={InputWithHint}
                              name="doors"
                              type="number"
                              digitOnly
                              numeric
                              id="field-doors"
                              data-name="doors"
                              data-limit="4"
                              placeholder="0"
                              label="Standard Doors"
                              hint="Standard door size assumes 21sq ft."
                              inputComponent={TextInput}
                              plain
                              max={10}
                              size="xlarge"
                              labelProps={labelProps}
                              labelContainerProps={labelContainerProps}
                              inputContainerProps={inputContainerProps}
                              onChange={setNumberValue}
                            />
                          </CalcField>
                          <CalcField icon="window">
                            <Field
                              component={InputWithHint}
                              name="windows"
                              type="number"
                              digitOnly
                              numeric
                              id="field-windows"
                              data-name="windows"
                              onKeyDown={preventNonNumericKeys}
                              data-limit="4"
                              placeholder="0"
                              label="Standard Windows"
                              hint="Standard window size assumes 10sq ft."
                              inputComponent={TextInput}
                              max={10}
                              plain
                              size="xlarge"
                              labelProps={labelProps}
                              labelContainerProps={labelContainerProps}
                              inputContainerProps={inputContainerProps}
                              onChange={setNumberValue}
                            />
                          </CalcField>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  {isMobile && (
                    <Box
                      margin={{ vertical: 'large' }}
                      direction="row"
                      gap="medium"
                      align="center"
                    >
                      <IconMeasure size="38px" color="black" />
                      <Text size="xlarge">Measurements</Text>
                    </Box>
                  )}

                  <Box
                    direction={isMobile ? 'column' : 'row'}
                    justify="between"
                    gap="large"
                    className="calc__row calc__row--footer"
                  >
                    <CalcField flex={true}>
                      <Field
                        component={InputWithHint}
                        width="100%"
                        name="length"
                        type="number"
                        digitOnly
                        numeric
                        id="field-length"
                        data-name="length"
                        data-limit="5"
                        placeholder="0 feet"
                        label="Length"
                        disabled={isWall}
                        hint={dimensionHintText}
                        inputComponent={TextInput}
                        onKeyDown={preventNonNumericKeys}
                        plain
                        max={10000}
                        size="xlarge"
                        labelProps={labelProps}
                        labelContainerProps={labelContainerProps}
                        inputContainerProps={inputContainerProps}
                        onChange={setNumberValue}
                      />
                    </CalcField>

                    <CalcField flex={true}>
                      <Field
                        component={InputWithHint}
                        width="100%"
                        name="width"
                        type="number"
                        value={values.width}
                        digitOnly
                        numeric
                        id="field-width"
                        data-name="width"
                        data-limit="5"
                        placeholder="0 feet"
                        label="Width"
                        hint={dimensionHintText}
                        inputComponent={TextInput}
                        onKeyDown={preventNonNumericKeys}
                        plain
                        max={10000}
                        size="xlarge"
                        labelProps={labelProps}
                        labelContainerProps={labelContainerProps}
                        inputContainerProps={inputContainerProps}
                        onChange={setNumberValue}
                      />
                    </CalcField>

                    <CalcField flex={true}>
                      <Field
                        component={InputWithHint}
                        width="100%"
                        name="height"
                        type="number"
                        digitOnly
                        numeric
                        id="field-height"
                        data-name="height"
                        data-limit="5"
                        placeholder="0 feet"
                        label="Height"
                        hint={dimensionHintText}
                        inputComponent={TextInput}
                        onKeyDown={preventNonNumericKeys}
                        plain
                        max={10000}
                        size="xlarge"
                        labelProps={labelProps}
                        labelContainerProps={labelContainerProps}
                        inputContainerProps={inputContainerProps}
                        onChange={setNumberValue}
                      />
                    </CalcField>

                    <Box>
                      <Button
                        disabled={!isValid}
                        type="submit"
                        tabIndex={0}
                        id="calc__cta"
                        label="Calculate"
                        primary
                        reverse
                        icon={<FormNextLink />}
                      />
                    </Box>
                  </Box>
                </Box>
              </Form>
            </Box>
          );
        }}
      </Formik>
    </div>
  );
};

CalcForm.propTypes = {
  productId: PropTypes.string,
  color: PropTypes.object,
  loadingProduct: PropTypes.bool,
  onCalculate: PropTypes.func,
  productHandle: PropTypes.string,
  onBrowse: PropTypes.func,
  subtitle: PropTypes.string,
  genericImage: PropTypes.object,
  paintCollections: PropTypes.array,
};

export default memo(CalcForm);

function getNumberValue(value: string | number) {
  if (typeof value === 'string') {
    return value === '' ? 0 : parseInt(value.replace(/[^0-9.]/g, ''));
  }
  return value;
}

function preventNonNumericKeys(e) {
  if (
    (e.keyCode < 48 || e.keyCode > 57) &&
    e.keyCode !== 8 &&
    e.keyCode !== 46 &&
    e.keyCode !== 9 &&
    e.keyCode !== 38 && // Up arrow key
    e.keyCode !== 40 // Down arrow key
  ) {
    e.preventDefault();
  }
}
