import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Text, Stack } from 'grommet';

import ButtonsProductCartOptions from '../ButtonsProductCartOptions';
import SmartLink from '../SmartLink';
import ImgixImage from '../ImgixImage';
import formatCurrency from '../../lib/formatCurrency';
import { getProductPath, getLowestVariantPrice } from '../../lib/product';

const ProductCardInfo = ({
  title,
  imageUrl,
  description,
  backgroundColor,
  variant,
  variants = [],
  sampleVariant,
  isDark,
  detailsPath,
  onAddToCart,
  collectionContext,
  showPrice = false,
  srcSetSizes = '600px',
  ...rest
}) => {
  const price = useMemo(
    () => (variant ? variant.price : getLowestVariantPrice(variants)),
    [variants, variant]
  );

  return (
    <Stack fill>
      <Box
        background={{
          color: backgroundColor,
        }}
        fill
      >
        <ImgixImage
          src={imageUrl}
          srcSetOptions={{
            q: 70,
            auto: ['format', 'compress'],
            ar: '1:1',
            fit: 'crop',
            crop: 'entropy',
          }}
          sizes={srcSetSizes}
          fit="cover"
          fill
        />
      </Box>
      <Box
        background={{
          dark: isDark,
        }}
        {...rest}
        align="center"
        justify="between"
      >
        <SmartLink
          fill
          to={detailsPath || getProductPath({ title, ...rest })}
          state={{ collection: collectionContext }}
          style={{ display: 'flex', textDecoration: 'none', flexGrow: 1 }}
        >
          <Box pad="medium" justify="center" gap="small" fill>
            {showPrice && (
              <Box
                flex={false}
                direction="row"
                gap="xsmall"
                wrap={true}
                justify="center"
                align="center"
                pad={{ vertical: 'xsmall' }}
              >
                {variant ? <Text>{variant.title}</Text> : <Text>From</Text>}
                <Text
                  size="medium"
                  style={{
                    borderBottom: '2px solid',
                  }}
                >
                  {formatCurrency(price)}
                </Text>
              </Box>
            )}

            <Heading
              level={2}
              size="small"
              textAlign="center"
              margin={{ bottom: 'large' }}
            >
              {title}
            </Heading>
            <Text size="small" textAlign="center">
              {description}
            </Text>
          </Box>
        </SmartLink>
        <Box fill="horizontal">
          <ButtonsProductCartOptions
            buttonFlex={{ shrink: 1, grow: 1 }}
            product={{ title, ...rest }}
            detailsPath={detailsPath || getProductPath({ title, ...rest })}
            collection={collectionContext}
            useIcon={true}
            variant={sampleVariant || variant}
            onAddToCart={onAddToCart}
            border={[
              { side: 'vertical', size: 'small' },
              { side: 'bottom', size: 'small' },
            ]}
          />
        </Box>
      </Box>
    </Stack>
  );
};

ProductCardInfo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  backgroundColor: PropTypes.string,
  detailsPath: PropTypes.string,
  isDark: PropTypes.bool,
  variant: PropTypes.object,
  variants: PropTypes.array,
  sampleVariant: PropTypes.object,
  onAddToCart: PropTypes.func,
  collectionContext: PropTypes.object,
  showPrice: PropTypes.bool,
  srcSetSizes: PropTypes.string,
};

export default memo(ProductCardInfo);
