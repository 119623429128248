import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'grommet';

const TextUnderlined = ({ children, ...rest }) => {
  return (
    <Text
      style={{
        borderBottom: `2px solid currentColor`,
      }}
      {...rest}
    >
      {children}
    </Text>
  );
};

TextUnderlined.propTypes = {
  children: PropTypes.node,
};

export default TextUnderlined;
