import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get } from '../lib/nodash';
import { Box, Button } from 'grommet';
import ButtonAddToCart from './ButtonAddToCart';
import IconArrow from './Icons/IconArrow';
import SmartLink from './SmartLink';
import { useLocation } from '@reach/router';

import {
  isSample,
  getProductPath,
  isMemo,
  canQuickAdd,
  isWallcovering,
} from '../lib/product';
import formatCurrency from '../lib/formatCurrency';
import encodeGid from '../lib/encodeGid';
import { track } from '../lib/analytics';
import { SAMPLE_ADDED } from '../lib/analytics/segmentActions';

const ButtonsProductCartOptions = ({
  product,
  collection,
  variant,
  useIcon = false,
  detailsPath,
  onAddToCart,
  direction = 'row-responsive',
  defaultBorders = false,
  buttonFlex = false,
  ...rest
}) => {
  const variantIdToAdd =
    encodeGid(get('shopifyId', variant)) || get('id', variant);
  const variantIsSample =
    isSample({ ...variant, product }) || isMemo({ ...variant, product });
  const variantPrice = formatCurrency(variant?.price?.amount ?? variant?.price);
  const location = useLocation();

  return (
    <Box direction={direction} {...rest}>
      <Box flex={buttonFlex}>
        <SmartLink
          to={detailsPath || getProductPath(product)}
          state={{ collection }}
          style={{ display: 'block', height: '100%', textDecoration: 'none' }}
        >
          <Button
            size="small"
            secondary
            label="Details"
            fill
            icon={
              useIcon ? <IconArrow direction="right" size="small" /> : undefined
            }
            reverse
            justify="between"
            style={
              defaultBorders
                ? undefined
                : {
                    borderBottom: 'none',
                    borderLeft: 'none',
                    borderRight: canQuickAdd ? 'none' : undefined,
                  }
            }
          />
        </SmartLink>
      </Box>
      {(variantIsSample || canQuickAdd(product)) && (
        <Box flex={buttonFlex}>
          <ButtonAddToCart
            variantId={variantIdToAdd}
            fill
            label={
              variantIsSample
                ? `${variantPrice} ${
                    isWallcovering(product) ? 'Memo' : 'Sample'
                  }`
                : 'Add'
            }
            useIcon={useIcon}
            size="small"
            style={{ whiteSpace: 'nowrap' }}
            onAddToCart={onAddToCart}
            onAdded={() =>
              track(SAMPLE_ADDED, {
                product: get('title', product),
                pdp: location.pathname !== '/paint',
                cdp: location.pathname === '/paint',
                location,
              })
            }
          />
        </Box>
      )}
    </Box>
  );
};

ButtonsProductCartOptions.propTypes = {
  product: PropTypes.object,
  collection: PropTypes.object,
  variant: PropTypes.object,
  useIcon: PropTypes.bool,
  detailsPath: PropTypes.string,
  onAddToCart: PropTypes.func,
  direction: PropTypes.string,
  defaultBorders: PropTypes.bool,
  buttonFlex: PropTypes.bool,
};

export default memo(ButtonsProductCartOptions);
