import React from 'react';
import { Blank } from 'grommet-icons';

const IconMeasure = (props) => (
  <Blank
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 44"
    {...props}
    style={{ strokeWidth: 0 }}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M31.6353,8.243a2.3715,2.3715,0,0,0-1.6614-.7,2.4222,2.4222,0,0,0-1.7343.7141l-14.37,14.72V2.413A.9322.9322,0,0,0,12.937,1.48H1.7441a.697.697,0,0,0-.0875,0l0,0a.9331.9331,0,0,0-.8453.9327v39.175a.9322.9322,0,0,0,.9327.9327H12.9373a.9322.9322,0,0,0,.9327-.9327V33.8932l6.296-1.6614a.9491.9491,0,0,0,.4226-.2332L36.4741,16.594a2.4278,2.4278,0,0,0,.0291-3.41ZM26.0832,13.14l.9327.9327L15.5022,25.5714l-.787-.787Zm2.2444,2.2444,1.02,1.02L17.834,27.9032l-1.02-1.0056ZM12.0046,26.4458l-1.8364,6.9811a.9338.9338,0,0,0,1.1368,1.1368l.7-.1895v6.2816H2.6772V34.593H7.3409a.9328.9328,0,1,0,0-1.8655H2.6772V26.6647H7.3409a.9328.9328,0,1,0,0-1.8655H2.6772V18.7364H7.3409a.9329.9329,0,1,0,0-1.8655H2.6772V10.8081H7.3409a.9329.9329,0,1,0,0-1.8655H2.6772V3.3461h9.3274Zm.3788,5.9025,1.4283-5.4943c.0054-.02.0109-.0382.0146-.0583l.0145-.0291c.0055-.0292.0109-.0583.0146-.0875l.0293-.0876L18.14,30.8472Zm7.5643-2.332-.8016-.787L30.6594,17.7161l.9619.9619ZM35.163,15.2532,32.9623,17.381l-5.5675-5.5675,2.1861-2.2444a.4806.4806,0,0,1,.3643-.16h0a.4694.4694,0,0,1,.35.1458l4.868,4.9407a.5408.5408,0,0,1,.16.3789A.5157.5157,0,0,1,35.163,15.2532Z"
    />
  </Blank>
);

export default IconMeasure;
