import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { get, head, flatten, map, isString } from '../../lib/nodash';
import { useSelector } from 'react-redux';

import ProductCard from '../Product/ProductCard';
import {
  findSampleVariant,
  getProductPath,
  findImageMetafield,
  findMetafieldValue,
  getMetafieldTags,
  hasDarkTag,
  isPrimer,
} from '../../lib/product';
import makeSelectPaintCollectionByProductId from '../../state/product/makeSelectPaintCollectionByProductId';
import findImage from '../../lib/findImage';
import deNodify from '../../lib/deNodify';

const PaintProductCard = (product) => {
  const {
    title,
    productType,
    products,
    description,
    overlay = true,
    productField,
    cta,
    link = true,
    images,
    collection,
    ...rest
  } = product;

  const selectPaintCollectionByProductId = useMemo(
    makeSelectPaintCollectionByProductId,
    []
  );
  const paintCollection = useSelector(
    (state) => collection || selectPaintCollectionByProductId(state, product.id)
  );

  const metafields = get('metafields', paintCollection);
  const color = useMemo(() => head(get('colors', product)), [product]);
  const hex_value = get('hex_value', color);
  const shortDescription = findMetafieldValue('short_description', metafields);
  const paintDetailImage = useMemo(() => {
    if (isPrimer({ productType })) {
      return findImage(deNodify(images), '_can');
    } else {
      return head(findImageMetafield('hero_image', metafields));
    }
  }, [metafields]);

  const collectionImage = useMemo(() => {
    if (isPrimer({ productType })) {
      return findImage(deNodify(images), '_grid');
    } else {
      const fields = findImageMetafield('collection_image', metafields);
      return Array.isArray(fields) ? head(fields) : fields;
    }
  }, [metafields, images, productType]);

  const tags = getMetafieldTags(metafields);
  const isDark = useMemo(() => hasDarkTag(tags), [metafields]);
  const variants = flatten(map('variants', products));

  const sampleVariant = useMemo(() => findSampleVariant(variants), [variants]);

  return (
    <ProductCard
      {...rest}
      sampleVariant={sampleVariant}
      isDark={isDark}
      imageUrl={
        isString(paintDetailImage)
          ? paintDetailImage
          : get('original_src', paintDetailImage)
      }
      collectionImageUrl={
        isString(collectionImage)
          ? collectionImage
          : get('original_src', collectionImage)
      }
      backgroundColor={hex_value}
      cta={cta}
      tags={tags}
      title={title}
      shopifyProducts={products}
      productType={productType}
      description={shortDescription || description}
      overlay={overlay}
      productField={productField}
      headingLevel={5}
      routeType={'paint'}
      variants={variants}
      detailsPath={link && product && getProductPath(product)}
    />
  );
};

PaintProductCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  productType: PropTypes.string,
  metafields: PropTypes.array,
  routeType: PropTypes.string,
  blob_image: PropTypes.object,
  description: PropTypes.string,
  overlay: PropTypes.bool,
  productField: PropTypes.string,
  headingLevel: PropTypes.number,
  cta: PropTypes.string,
  link: PropTypes.bool,
};

export default memo(PaintProductCard);
