import React from 'react';
import { Blank } from 'grommet-icons';

const IconWindow = (props) => (
  <Blank
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 55"
    {...props}
    style={{ strokeWidth: 0 }}
  >
    <path d="M.9629.5v54H39.0371V.5Zm36.0742,23H20.9287V2.5H37.0371Zm0,2v21H20.9287v-21ZM2.9629,2.5H18.9287v21H2.9629Zm15.9658,23v21H2.9629v-21ZM2.9629,52.5v-4H37.0371v4Z"></path>
  </Blank>
);

export default IconWindow;
