import React from 'react';
import { Blank } from 'grommet-icons';

const IconCube = (props) => (
  <Blank
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 55 55"
    {...props}
    style={{ strokeWidth: 0 }}
  >
    <path d="M51.8538,10.3672,27.82.9326a.8614.8614,0,0,0-.5171-.0474.5825.5825,0,0,0-.1138.0439L3.1482,10.3662a.8386.8386,0,0,0-.5244.7822V43.8516a.8383.8383,0,0,0,.5254.7852l24.0371,9.4355a.836.836,0,0,0,.314.0615.8226.8226,0,0,0,.3115-.0605l24.0391-9.4365a.8377.8377,0,0,0,.5254-.7822V11.15A.835.835,0,0,0,51.8538,10.3672Zm-25.19,41.6963L4.2966,43.2988V12.376l22.3667,8.7817ZM27.5,19.6748,5.7566,11.1479,27.5,2.6035l21.7427,8.5444Zm23.2036,23.624L28.3362,52.0635V21.1577L50.7034,12.376Z"></path>
  </Blank>
);

export default IconCube;
