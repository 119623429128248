import React from 'react';
import PropTypes from 'prop-types';
import { RadioButtonGroup } from 'grommet';

const InputRadioButtonGroup = (props) => {
  return <RadioButtonGroup {...props.field} {...props} />;
};

export default InputRadioButtonGroup;

InputRadioButtonGroup.propTypes = {
  field: PropTypes.object,
};
