import gql from 'graphql-tag';

export const GET_FULL_PRODUCT = gql`
  query FullProduct($productId: ID!) {
    node(id: $productId) {
      ... on Product {
        title
        id
        handle
        productType
        descriptionHtml
        tags
        images(first: 5) {
          edges {
            node {
              originalSrc
            }
          }
        }
        excludedVariantsFromPicker: metafield(
          namespace: "custom"
          key: "excluded_picker_variants"
        ) {
          type
          value
        }
        variants(first: 5) {
          edges {
            node {
              title
              price {
                amount
              }
              id
              currentlyNotInStock
              quantityAvailable
              image {
                thumb: url(transform: { maxHeight: 150, maxWidth: 150 })
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCTS_BY_IDS = gql`
  query ProductsByIds($productIds: [ID!]!) {
    nodes(ids: $productIds) {
      ... on Product {
        title
        id
        handle
        productType
        descriptionHtml
        description
        tags
        onlineStoreUrl
        images(first: 5) {
          edges {
            node {
              originalSrc
            }
          }
        }
        variants(first: 4) {
          edges {
            node {
              title
              price {
                amount
              }
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_SELECTED_AND_RECOMMENDED_PRODUCTS = gql`
  fragment suggestedProductFields on Product {
    title
    id
    handle
    productType
    onlineStoreUrl
    descriptionHtml
    description
    priceRange {
      maxVariantPrice {
        amount
      }
    }
    variants(first: 6) {
      edges {
        node {
          title
          id
          currentlyNotInStock
          quantityAvailable
          priceV2 {
            amount
          }
          product {
            id
          }
        }
      }
    }
    images(first: 5) {
      edges {
        node {
          originalSrc
        }
      }
    }
  }

  query SelectedProductsAndRecommendedTools($productIds: [ID!]!) {
    paint: nodes(ids: $productIds) {
      ... on Product {
        ...suggestedProductFields
      }
    }
    essentialsKit: productByHandle(handle: "essentials-kit") {
      ...suggestedProductFields
    }
    recommendedTools: collectionByHandle(handle: "recommended-tools") {
      products(first: 100) {
        edges {
          node {
            ...suggestedProductFields
          }
        }
      }
    }
  }
`;

export const GET_FULL_PRODUCT_BY_VARIANT_ID = gql`
  query FullProductByVariantId($variantId: ID!) {
    node(id: $variantId) {
      ... on ProductVariant {
        id
        title
        product {
          title
          id
          handle
          productType
          tags
          descriptionHtml
          excludedVariantsFromPicker: metafield(
            namespace: "custom"
            key: "excluded_picker_variants"
          ) {
            type
            value
          }
          images(first: 5) {
            edges {
              node {
                originalSrc
              }
            }
          }
          variants(first: 5) {
            edges {
              node {
                currentlyNotInStock
                quantityAvailable
                title
                price {
                  amount
                }
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_FULL_PRODUCT_BY_HANDLE = gql`
  query FullProductByHandle($productHandle: String!) {
    productByHandle(handle: $productHandle) {
      title
      id
      handle
      productType
      descriptionHtml
      tags
      images(first: 3) {
        edges {
          node {
            originalSrc
          }
        }
      }
      variants(first: 2) {
        edges {
          node {
            title
            price {
              amount
            }
            id
          }
        }
      }
    }
  }
`;
