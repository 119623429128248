import React from 'react';
import { Blank } from 'grommet-icons';

const IconDoor = (props) => (
  <Blank
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 55"
    {...props}
    style={{ strokeWidth: 0 }}
  >
    <path d="M28.2576,32.9394a3.0246,3.0246,0,1,1,3.0246-3.0246A3.0246,3.0246,0,0,1,28.2576,32.9394Zm0-4.0249a1,1,0,1,0,1,1,1,1,0,0,0-1-1Zm7.2438,25.7592H4.4985V.3263H35.5015ZM6.5227,52.6494H33.4772V2.3506H6.5228Z"></path>
  </Blank>
);

export default IconDoor;
