import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Drop, Stack, TextInput } from 'grommet';

const InputWithHint = ({
  inputComponent,
  hint,
  label,
  id,
  labelProps,
  labelContainerProps,
  inputContainerProps,
  field,
  ...rest
}) => {
  const Component = inputComponent || TextInput;
  const [focused, setFocused] = useState(false);
  const targetRef = useRef(null);
  return (
    <Box
      ref={targetRef}
      direction="row"
      justify="start"
      align="end"
      fill
      style={{ transition: 'opacity 0.5s', opacity: rest.disabled ? 0.3 : 1 }}
      border={{ side: 'bottom', size: 'small' }}
    >
      <Stack fill="horizontal">
        <Box
          flex={{ grow: 1, shrink: 0 }}
          pad={{ right: 'xlarge' }}
          {...labelContainerProps}
        >
          <Text
            as="label"
            htmlFor={id}
            {...labelProps}
            color={focused ? 'dark-4' : 'black'}
          >
            {label}
          </Text>
        </Box>
        <Box flex={true} {...inputContainerProps} className="input-container">
          <Component
            placeholder={rest.placeholder || label}
            id={id}
            style={{ textAlign: 'right' }}
            {...field}
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={(e) => {
              setFocused(false);
              field.onBlur(e);
            }}
            {...rest}
          />
        </Box>
      </Stack>
      {focused && (
        <Drop
          trapFocus={false}
          stretch="align"
          animation="fadeIn"
          target={targetRef.current}
          align={{ top: 'bottom', left: 'left' }}
          pad="medium"
          background="light-1"
          elevation="none"
        >
          <Box className="cfield__input-note-controlled">
            <Text size="small">{hint}</Text>
          </Box>
        </Drop>
      )}
    </Box>
  );
};

InputWithHint.propTypes = {
  inputComponent: PropTypes.elementType,
  labelProps: PropTypes.object,
  labelContainerProps: PropTypes.object,
  inputContainerProps: PropTypes.object,
  hint: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  id: PropTypes.string,
  field: PropTypes.object.isRequired,
};

export default InputWithHint;
