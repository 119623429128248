import { sum, values, keys, pickBy, lowerCase } from '../nodash';

export const calculateGallons = (sqFt) => {
  return Math.round((sqFt / 375) * 2) || 1;
};

export const totalSquareFootage = (sqFtObj) => {
  return sum(values(sqFtObj));
};

export const calculateSquareFootage = (
  { length = 0, height = 0, width = 0, windows = 0, doors = 0 },
  areaType = 'room'
) => {
  const isRoom = lowerCase(areaType) === 'room';

  const availableDimensions = pickBy((x) => x !== 0, { length, height, width });
  const availableKeys = keys(availableDimensions);

  const twoDCalc =
    availableKeys.length < 3
      ? availableDimensions[availableKeys[0]] *
          availableDimensions[availableKeys[1]] -
        windows * 10 -
        doors * 28
      : width * height - windows * 10 - doors * 28;

  const sqFt =
    isRoom && availableKeys.length === 3
      ? length * 2 * height + width * 2 * height - windows * 10 - doors * 28
      : twoDCalc;
  return sqFt;
};
