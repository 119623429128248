import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Box, Text } from 'grommet';
import { get, find } from '../../lib/nodash';
import styled from 'styled-components';
import { FormDown } from 'grommet-icons';

import useMobile from '../useMobile';

const DropContent = styled(Box)`
  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    &:hover {
      .inner-text {
        color: #fff;
      }
    }
  }
`;

const Option = ({ option, active, disabled, selected, size }) => {
  const isMobile = useMobile();
  return (
    <DropContent
      pad={
        disabled
          ? { vertical: 'xsmall', horizontal: isMobile ? 'medium' : 'small' }
          : isMobile
          ? 'medium'
          : 'small'
      }
      background={selected ? 'black' : active ? 'light-3' : 'transparent'}
    >
      <Text size={size} className="inner-text" weight={disabled ? 400 : 400}>
        {get('label', option)}
      </Text>
      <Text className="inner-text" size="small">
        {get('description', option)}
      </Text>
    </DropContent>
  );
};

Option.propTypes = {
  option: PropTypes.object,
  index: PropTypes.number,
  options: PropTypes.object,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  size: PropTypes.string,
};

const InputSelectWithDescription = ({
  field,
  form,
  options,
  size,
  alignSelf = 'stretch',
  className,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const iconSize =
    size === 'xsmall'
      ? '14px'
      : size === 'small'
      ? '16px'
      : size === 'large'
      ? '20px'
      : size === 'xlarge'
      ? '24px'
      : '16px';
  return (
    <Box
      border={{
        side: 'all',
        size: '1px',
        color: open ? 'light-4' : 'transparent',
      }}
    >
      <Select
        {...field}
        {...rest}
        size={size}
        plain
        alignSelf={alignSelf}
        options={options}
        multiple={false}
        valueKey={{ key: 'value', reduce: true }}
        value={field.value}
        valueLabel={(v) => {
          const optionFromOptions = find({ value: v }, options);
          return (
            <Box pad="small">
              <Text size="large">{get('label', optionFromOptions)}</Text>
              <Text color="dark-3" size="small">
                {get('description', optionFromOptions)}
              </Text>
            </Box>
          );
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(e) => {
          e.stopPropagation();
          form.setFieldValue(field.name, e.value);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        icon={<FormDown size={iconSize} color="black" />}
        style={{
          backgroundColor: open ? 'white' : 'transparent',
          outline: 'none',
        }}
        className={className}
      >
        {(option, index, options, { active, disabled, selected }) => (
          <Option
            option={option}
            index={index}
            options={options}
            active={active}
            disabled={disabled}
            selected={selected}
            size={size}
          />
        )}
      </Select>
    </Box>
  );
};

InputSelectWithDescription.propTypes = {
  options: PropTypes.array,
  field: PropTypes.object.isRequired,
  form: PropTypes.object,
  size: PropTypes.string,
  alignSelf: PropTypes.string,
  className: PropTypes.string,
};

export default memo(InputSelectWithDescription);
